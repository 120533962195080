<template>
  <div class="users">
    <div>
      <CustomerInfo v-if="unit" type="unit" :customerInfo="unit" :alarmCenters="alarm_centrals" @updateAddress="unit = $event" />
      <div class="w-full pt-5" v-if="(user.role == 'admin')">
        <textarea id="big-input" v-model="admin_notes" class="w-full h-48 p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm" placeholder="Anteckningar för admin" :disabled="isDisabled"></textarea>
      </div>
      <div class="w-full pt-2 pb-4 items-center" v-if="(user.role == 'admin')">
        <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click="toggleDisabled">
          {{ isDisabled ? 'Lås upp anteckningar' : 'Lås anteckningar' }}
          <BaseIcon :icon="isDisabled ? 'lock' : 'lock-open'" class="ml-1"/>
        </button>
        <button class="btn-green-outline mx-2 mt-3 sm:mt-0" v-if="(!this.isDisabled)" @click.prevent="saveAdminNotes()">
          Spara
          <BaseIcon icon="save" class="ml-1" />
        </button>
      </div>
      <AlarmInfo :alarmInfo="alarm_info" :units="units" :customerId="customer_id" :id="id" :unitAlarms="alarms" :devicesTree="devicesTree" @updateAlarm="getAlarms()" @updateDeviceTree="getDevicesTree()" />
      <AlarmDetail :alarmInfo="alarm_info" :alarmCentrals="alarm_centrals" :sellers="sellers" :customer="customer" @updateAlarmContract="getAlarms()" />
      <ErrorReport :report="errorReport" />
      <OrderAlarmModal :unit_id="unit_id" />
      <AdminOrderAlarmModal :unit_id="unit_id" :sellers="sellers" :alarm_centers="alarm_centrals" :customer="customer" :unit="unit" />
      <vue-good-table
        styleClass="vgt-table bordered"
        row-style-class="text-sm"
        class="mt-12"
        ref="alarmTable"
        :columns="alarm_columns"
        :rows="alarms_rows"
        :search-options="{ enabled: false }"
        :pagination-options="{ enabled: false }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          disableSelectInfo: true,
          selectAllByGroup: false,
        }"
        :group-options="tableGroupOptions"
      >
        <div slot="table-actions">
          <div class="flex flex-wrap">
            <router-link class="btn-green-outline mx-2 mt-2 xl:mt-0" v-if="user.role == 'admin' || user.role == 'seller'" :to="'/admin/skapa-larm/' + customer_id + '/' + unit_id">
              {{ $t("departments_alarm.new_product") }}
              <BaseIcon icon="plus" class="ml-1" />
            </router-link>
            <button class="btn-green-outline mx-2 mt-2 xl:mt-0" @click.prevent="showAdminOrderModal()" v-if="user.role == 'admin' || user.role == 'seller'">
              {{ $t("departments_alarm.order") }}
              <BaseIcon icon="arrow-right" class="ml-1" />
            </button>
            <button class="btn-red-outline mx-2 mt-2 xl:mt-0" v-if="user.role == 'admin' && alarms && alarms.length > 0" @click.prevent="deleteAlarmsPrompt()">
              {{ $t("departments_alarm.delete") }}
              <BaseIcon icon="trash" class="ml-1" />
            </button>
            <router-link class="btn-secondary-outline mx-2 mt-2 xl:mt-0" v-if="user.role == 'admin' || user.role == 'seller'" :to="'/unit/floor-plan/' + customer_id + '/' + unit_id">
              {{ $t("departments_alarm.floor_plan") }}
              <BaseIcon icon="building" class="ml-1" />
            </router-link>
            <button class="btn-green-outline mx-2 mt-2 xl:mt-0" @click.prevent="showOrderModal()" v-if="user.role == 'superuser' || user.role == 'user'">
              {{ $t("departments_alarm.order_new_alarm") }}
              <BaseIcon icon="arrow-right" class="ml-1" />
            </button>
            <router-link class="btn-secondary-outline mx-2 mt-2 xl:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('securtrack') > -1)) && alarms && alarms.length > 0" :to="'/gps-unit/customer/' + unit_id + '/-'">
              {{ $t("departments_alarm.secur_track") }}
              <BaseIcon icon="map-marker-alt" class="ml-1" />
            </router-link>
            <router-link class="btn-secondary-outline mx-2 mt-2 xl:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('securtool') > -1)) && alarms && alarms.length > 0" :to="'/ronderingsbevakning/unit/' + unit_id">
              {{ $t("departments_alarm.secur_tool") }}
              <BaseIcon icon="history" class="ml-1" />
            </router-link>
            <router-link class="btn-secondary-outline mx-2 mt-2 xl:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('test_reminder') > -1)) && alarms && alarms.length > 0" :to="'/alarm-reminder/unit/' + unit_id">
              {{ $t("departments_alarm.test_alarm") }}
              <BaseIcon icon="bell" class="ml-1" />
            </router-link>
            <button class="btn-blue-outline mx-2 mt-2 xl:mt-0" @click.prevent="exportAlarms()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
          </div>
        </div>
        <template slot="table-header-row" slot-scope="props">
          <span class="ml-5">
            {{ props.row.label }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'lastSeen'">
            <span v-if="!new RegExp('(SRT334)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)|(SecurDesktop)').test(props.row.type)">
              <span v-if="props.row.lastSeen">
                <span v-if="new RegExp('^SRT').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                <span v-if="new RegExp('^TWIG').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                <span v-if="new RegExp('^EMERIT').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                <span v-if="new RegExp('^TELTONIKA').test(props.row.type)" class="last-seen" v-bind:class="{ inactive: props.row.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
              </span>
              <span v-else>
                <span class="last-seen grey">⬤</span>
              </span>
            </span>
            <span v-else-if="new RegExp('(SRT334)|(TWIG Beacon)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(props.row.type)">
              <!-- todo: better last seen time for ajax devices, since they are updated every X hours -->
              <span v-if="props.row.lastSeen">
                <span class="last-seen" v-bind:class="{ inactive: props.row.tagLastSeen > 56, orange: props.row.tagLastSeen > 28 && props.row.tagLastSeen <= 56 }">⬤</span>
              </span>
              <span v-else-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(props.row.type)">
                <BaseIcon icon="camera" class="ml-1" />
              </span>
              <span v-else>
                <span class="last-seen grey">⬤</span>
              
              </span>
            </span>
            <span v-else-if="/(4G Box)/.test(props.row.type)">
              <span v-if="props.row.lastSeen">
                <span class="last-seen" v-bind:class="{ inactive: props.row.tagLastSeen > 7, orange: props.row.tagLastSeen > 2 && props.row.tagLastSeen <= 7 }">⬤</span>
              </span>
              <span v-else>
                <span class="last-seen grey">⬤</span>
              </span>
            </span>
          </span>
          <span v-else-if="props.column.field == 'sellerName' && /^admin|^seller/.test(user.role)" class="text-xxs">
            {{ getSellerName(props.row.seller) }}
          </span>
          <span v-else-if="props.column.field == 'errorReport'">
            <button class="btn-red-outline opacity-75 hover:opacity-100" @click.prevent="showErrorReport(props.row)">{{ $t("departments_alarm.error_reporting") }}</button>
          </span>
          <span v-else-if="props.column.field == 'viewAlarm'">
            <button class="btn-blue-outline" @click.prevent="showAlarmInfoModal(props.row)">{{ $t("view") }}</button>
          </span>
          <span v-else-if="props.column.field == 'downloadPdf' && !/(^SRT330$)|(^SRT430$)|(^SRT334$)|(^TWIG Beacon$)|(^4G Box$)|(^TELTONIKA)|(^Anchor Beacon)|(^Avigilon Alta Compact Dome 5MP)|(^AJAX)/.test(props.row.type)">
            <span @click="downloadPDF(props.row)"><BaseIcon icon="file-pdf" class="text-red-600 text-2xl cursor-pointer"/></span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5">
          <div class="text-sm mb-4">{{ $t("departments_alarm.no_alarm_message") }}</div>
          <div v-if="user.role == 'superuser' || user.role == 'user'">
            <button class="btn-blue px-5 py-4 max-w-sm" @click.prevent="showOrderModal()">
              {{ $t("departments_alarm.order_new_alarm") }}
              <BaseIcon icon="arrow-right" class="ml-1" />
            </button>
          </div>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import CustomerInfo from "@/components/customer_info";
import AlarmInfo from "@/components/modal/alarm_info";
import AlarmDetail from "@/components/modal/alarm_detail";
import ErrorReport from "@/components/modal/error_report";
import OrderAlarmModal from "@/components/modal/order_alarm";
import AdminOrderAlarmModal from "@/components/modal/admin_order_alarm";

export default {
  title() {
    return this.$t("page_titles.alarm_view");
  },
  components: {
    CustomerInfo,
    AlarmInfo,
    AlarmDetail,
    ErrorReport,
    OrderAlarmModal,
    AdminOrderAlarmModal,
  },
  data() {
    return {
      type: this.$route.params.type,
      customer_id: this.$route.params.customer_id,
      unit_id: this.$route.params.unit_id,
      id: this.$route.params.id,
      selected_alarm_id: this.$route.params.alarmId,
      modalShown: false,
      tableGroupOptions: {
        enabled: true,
        collapsable: true,
      },
      alarms: [],
      alarms_rows: [],
      unit: null,
      units: null,
      devicesTree: [],
      customer: null,
      alarm_centrals: [],
      sellers: [],
      errorReport: null,
      alarm_info: null,
      isDisabled: true,
      admin_notes: "", 
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    alarm_columns() {
      return [
        { label: this.$t("departments_alarm_table.alarm_type"), field: "type", sortable: true, thClass: "text-sm" },
        { label: this.$t("departments_alarm_table.product_name"), field: "unit_name", sortable: true, thClass: "text-sm" },
        { label: this.$t("departments_alarm_table.phone_number"), field: "phone", sortable: true, thClass: "text-sm" },
        { label: this.$t("departments_alarm_table.serial_number"), field: "modelnumber", sortable: true, thClass: "text-sm" },
        { label: this.$t("departments_alarm_table.status"), field: "lastSeen", sortable: false, thClass: "text-sm" },
        { label: "", field: "sellerName", sortable: false },
        { label: "", field: "errorReport", sortable: false, thClass: "text-sm" },
        { label: "", field: "viewAlarm", sortable: false, thClass: "text-sm" },
        { label: "", field: "downloadPdf", sortable: false, thClass: "text-sm" },
      ];
    },
  },

  methods: {
    getAlarms() {
      var vm = this;
      let apiUrl = "";

      if (this.type == "customer" && (this.user.role == "superuser" || this.user.role == "user" || this.user.role == "admin" || this.user.role == "seller")) {
        apiUrl = `${process.env.VUE_APP_SERVER_URL}/unit/alarms/${this.unit_id}`;
      } else if (this.type == "reseller" && (this.user.role == "reseller" || this.user.role == "admin")) {
        apiUrl = `${process.env.VUE_APP_SERVER_URL}/reseller/${this.id}/unit/alarms/${this.unit_id}`;
      } else {
        this.alarms = [];
        return;
      }

      this.axios
        .get(apiUrl)
        .then((response) => {
          var larms = [],
            otherProducts = [];
          for (let i = 0; i < response.data.unit_alarms.length; i++) {
            let el = response.data.unit_alarms[i];
            let a = _.find(response.data.gps_alarms, { imei_number: el.imei_number });

            if (el.gps_active && a) {
              let lastSeenObj = this.getAlarmLastSeenTime(a);
              el.lastSeen = lastSeenObj.lastSeen;
              el.lastSeenTime = lastSeenObj.lastSeenTime;
            }
            // tag status
            if (/(^SRT334$)|(^TWIG Beacon$)|(^4G Box$)|(^Anchor Beacon$)|(^Avigilon Alta Compact Dome 5MP)|(^AJAX)|(^SecurDesktop)/.test(el.type) && el.last_rftag_activity) {
              el.lastSeen = this.moment(el.last_rftag_activity).fromNow();
              el.tagLastSeen = this.moment().diff(this.moment(el.last_rftag_activity), "days");
            }

            if (/(^SRT334$)|(^TWIG Beacon$)|(^4G Box$)|(^Anchor Beacon$)|(^Avigilon Alta Compact Dome 5MP)|(^AJAX)|(^SecurDesktop)/.test(el.type)) otherProducts.push(el);
            else larms.push(el);
          }
          this.alarms = [...larms, ...otherProducts];

          if (this.alarms.length == 0) {
            this.tableGroupOptions = {
              enabled: false,
              collapsable: false,
            };
            this.alarms_rows = [];
          } else {
            this.tableGroupOptions = {
              enabled: true,
              collapsable: true,
            };

            this.alarms_rows = [
              {
                mode: "span",
                label: "Larms",
                children: larms,
              },
              {
                mode: "span",
                label: "Andra produkter",
                children: otherProducts,
              },
            ];

            this.$nextTick().then(() => {
              vm.$refs["alarmTable"].expandAll();
            });
          }

          if (this.selected_alarm_id && !this.modalShown) {
            let a = _.find(this.alarms, { _id: this.selected_alarm_id });
            if (a) this.showAlarmInfoModal(a);
          }

          this.selectAllRows();
          this.modalShown = true;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/${this.customer_id}`)
        .then((response) => {
          if (response.data.customer) this.customer = response.data.customer;

          this.units = response.data.units;
          this.findUnit();
          if (this.unit) this.setPageTitle(`${this.unit.name} - ${this.$t("top_titles.alarm_view")}`, "alarms");
          this.admin_notes = this.unit.admin_notes;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getDevicesTree() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/customer/${this.customer_id}/devices`)
        .then((response) => {
          this.devicesTree = response.data.devicesTree;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarm_centrals = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    findUnit() {
      let u = _.find(this.units, { unit_id: this.unit_id });
      if (u) this.unit = u;
    },

    showAlarmInfoModal(alarm) {
      this.alarm_info = _.cloneDeep(alarm);
      this.show("update-alarm");
    },

    showErrorReport(alarm) {
      this.errorReport = {
        model: alarm.modelnumber,
        phone: alarm.phone,
        imei_number: alarm.imei_number,
        customer_name: this.customer.name,
        unit_name: this.getUnitName(alarm.parent_id),
        contact_phone: this.unit.contact_name,
        contact_email: this.user.email,
        message: "",
      };
      this.show("error-report");
    },

    exportAlarms() {
      const newColumns = this.$refs["alarmTable"].selectedRows.map((item) => ({
        [this.$t("department_export.department")]: item.alarm_unit_name,
        [this.$t("department_export.device_type")]: item.type,
        [this.$t("department_export.device_name")]: item.unit_name,
        [this.$t("department_export.phone")]: item.phone,
        [this.$t("department_export.serial_number")]: item.modelnumber,
        [this.$t("department_export.alarm_center")]: item.alarm_central,
      }));

      this.excelExport(newColumns, "larms", "larm_rapport");
    },

    deleteAlarmsPrompt() {
      if (this.$refs["alarmTable"].selectedRows.length > 0) {
        this.$modal.show("dialog", {
          title: `<span class="text-red-500">Radera ${this.$refs["alarmTable"].selectedRows.length} larm?</span>`,
          buttons: [
            {
              title: `<div class="bg-accent-500 text-white text-sm font-sans py-2">${this.$t("user_actions.cancel")}</div>`,
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
            {
              title: `<div class="bg-red-500 text-white text-sm font-sans py-2">${this.$t("user_actions.delete")}</div>`,
              handler: () => {
                this.deleteSelectedAlarms();
              },
            },
          ],
        });
      }
    },

    async deleteSelectedAlarms() {
      try {
        let alarmIDs = _.map(this.$refs["alarmTable"].selectedRows, "_id");
        let data = {
          alarmIDs: alarmIDs,
        };

        // eslint-disable-next-line
        let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/unit/${this.unit_id}/alarms/delete`, data);

        this.$modal.hide("dialog");
        this.handleSuccess("Larm har tagits bort");

        this.getAlarms();
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    async downloadPDF(alarm) {
      try {
        let show_alarm_number = false;

        let a = _.find(this.alarm_centrals, { _id: alarm.alarm_central });
        if (a && /(Stanley Security)|(Securitas AB)|(Rapid Larmcentral)|(Cubsec Larmcentral)/.test(a.name)) show_alarm_number = true;

        let doc = await this.generateAlarmPlanPDF({ alarm: alarm, customerName: this.customer.name, sellers: this.sellers, oldAlarm: alarm, highlight: false, showAlarmNumber: show_alarm_number });
        doc.save(`${this.getUnitName(alarm.parent_id)}-${alarm.unit_name}-${alarm.phone}.pdf`);
      } catch (error) {
        this.handleError(error);
      }
    },

    selectAllRows() {
      for (let i = 0; i < this.alarms.length; i++) {
        this.$set(this.alarms[i], "vgtSelected", true);
      }
    },

    getUnitName(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u && u.name) return u.name;
      else return "-";
    },

    getAlarmLastSeenTime: function(a) {
      return {
        lastSeen: this.moment(a.updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(a.updatedAt)),
      };
    },

    getSellerName(id) {
      let s = _.find(this.sellers, { _id: id });
      if (s) return s.name;
      else return "";
    },

    show(id) {
      this.$modal.show(id);
    },

    showOrderModal() {
      this.$modal.show("modal-order-alarm");
    },

    showAdminOrderModal() {
      this.$modal.show("modal-admin-order-alarm");
    },

    hide(id) {
      this.$modal.hide(id);
    },
    toggleDisabled() {
      this.isDisabled = !this.isDisabled;
    },
    async saveAdminNotes() {
      let data = {
          admin_notes: this.admin_notes
      };
      if (this.unit_id && data) {
        let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/unit/admin-notes/${this.unit_id}`, data);
        if (!response) {
          this.handleError(response.message);
        } else {
          this.toggleDisabled()
        }
      } else return
    }
  },

  created() {
    this.getAlarms();
    this.getCustomer();
    this.getAlarmCentrals();
    this.getSellersResellers();
    this.getDevicesTree();
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.alarm_view");
      this.setPageTitle(`${this.unit.name} - ${this.$t("top_titles.alarm_view")}`, "alarms");
    },
  },
};
</script>
